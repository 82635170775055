<template>
  <div class="block-container flex flex-col rounded-xl bg-white items-stretch">
    <div
      v-if="title"
      class="block-container__title py-2 px-4 bg-primary text-white-text rounded-t-xl font-semibold bg-primary"
    >
      {{ title }}
    </div>
    <div class="block-container__content p-4 flex-1" :class="contentClass">
      <slot/>
    </div>
  </div>
</template>

<script>
// ! use shared/components/ui/ContentBox instead

export default {
  props: {
    title: String,
    contentClass: [String, Array, Object],
  },
}
</script>

<style lang="scss" scoped>
 
</style>